.fullscreen-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; /* No scrolling for this container */
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    overflow: auto; /* Adds scroll if content exceeds screen size */
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.logo {
    width: 128px;
    height: 128px;
}

.qr-container {
    width: 100%;
    max-height: calc(100vh - 256px); /* Limits the QR reader's height */
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden; /* Keeps the reader within bounds */
}

#qr-reader {
    width: 100%;
    height: 100%; /* Full height of its container */
}
